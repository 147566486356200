.App {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.chat-interface {
  display: flex;
  flex-direction: column;
  height: 70vh;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.chat-history {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.message {
  max-width: 70%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.user {
  align-self: flex-end;
  background-color: #007bff;
  color: white;
}

.ai {
  align-self: flex-start;
  background-color: #f1f1f1;
}

/* .chat-input {
  display: flex;
  padding: 10px;
}

.chat-input input {
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
}

.chat-input button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
} */